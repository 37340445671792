:root {
  --aside-width: 400px;
  --cart-aside-summary-height-with-discount: 300px;
  --cart-aside-summary-height: 250px;
  --grid-item-width: 355px;
  --header-height: 64px;
  --color-dark: #000;
  --color-light: #fff;
  font-family: spaceGrotesk;
}


@media only screen and (max-width: 767px) {
  .satellite-bubble.chspt-wrapper.chspt-position-right.svelte-1rdizjn.svelte-1rdizjn {
    right: 1rem;
    bottom: 60px;
  }

  .satellite-bubble.chspt-wrapper.svelte-1rdizjn .satellite-bubble-button.chspt-size-md.svelte-1rdizjn {
    height: 4em!important;
    width: 4em!important;
  }
}



.cart-animation {
  transition: transform 40000ms ease-in-out;
}

img {
  border-radius: 4px;
}

/*
* --------------------------------------------------
* components/Aside
* --------------------------------------------------
*/
aside {
  background: var(--color-light);
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.3);
  height: 100vh;
  /*max-width: var(--aside-width);*/
  /*min-width: var(--aside-width);*/
  position: fixed;
  right: calc(-1 * var(--aside-width));
  top: 0;
  transition: transform 200ms ease-in-out;
}

aside header {
  align-items: center;

  display: flex;
  height: var(--header-height);
  justify-content: space-between;
  padding: 0 20px;
}

aside header h3 {
  margin: 0;
}

aside header .close {
  font-weight: bold;
  opacity: 0.8;
  text-decoration: none;
  transition: all 200ms;
  width: 20px;
  &:hover {
    opacity: 1;
  }
}

aside header h2 {
  margin-bottom: 0.6rem;
  margin-top: 0;
}

aside main {
  margin: 1rem;
}

aside p {
  margin: 0 0 0.25rem;
  &:last-child {
    margin: 0;
  }
}

aside li {
  margin-bottom: 0.125rem;
}

.overlay {
  background: rgba(0, 0, 0, 0.2);
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 400ms ease-in-out;
  transition: opacity 400ms;
  visibility: hidden;
  z-index: 10;
}

.overlay .close-outside {
  background: transparent;
  border: none;
  color: transparent;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: calc(100% - var(--aside-width));
}

.overlay .light {
  background: rgba(255, 255, 255, 0.5);
}

.overlay .cancel {
  cursor: default;
  height: 100%;
  position: absolute;
  width: 100%;
}

.overlay {
  &:target {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
  }
  /* reveal aside */
  &:target aside {
    transform: translateX(calc(var(--aside-width) * -1));
  }
}

/*
* --------------------------------------------------
* components/Header
* --------------------------------------------------
*/
.header {
  align-items: center;
  background: #fff;
  display: flex;
  height: var(--header-height);
  padding: 0 1rem;
  position: sticky;
  top: 0;
  z-index: 1;
}

.header-menu-mobile-toggle {
  @media (min-width: 48em) {
    display: none;
  }
}

.header-menu-mobile {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
}

.header-menu-desktop {
  display: none;
  grid-gap: 1rem;
  @media (min-width: 45em) {
    display: flex;
    grid-gap: 1rem;
    margin-left: 3rem;
  }
}

.header-menu-item {
  cursor: pointer;
}

.header-ctas {
  align-items: center;
  display: flex;
  grid-gap: 1rem;
  margin-left: auto;
}

/*
* --------------------------------------------------
* components/Footer
* --------------------------------------------------
*/
.footer {
  background: var(--color-dark);
  margin-top: auto;
}

.footer-menu-missing {
  display: inline-block;
  margin: 1rem;
}

.footer-menu {
  align-items: center;
  display: flex;
  grid-gap: 1rem;
  padding: 1rem;
}

.footer-menu a {
  color: var(--color-light);
}

/*
* --------------------------------------------------
* components/Cart
* --------------------------------------------------
*/
.cart-main {
  height: 100%;
  max-height: calc(100vh - var(--cart-aside-summary-height));
  overflow-y: auto;
  width: auto;
}

.cart-main.with-discount {
  max-height: calc(100vh - var(--cart-aside-summary-height-with-discount));
}

.cart-line {
  display: flex;
  padding: 0.75rem 0;
}

.cart-line img {
  height: 100%;
  display: block;
  margin-right: 0.75rem;
}

.cart-summary-page {
  position: relative;
}

.cart-summary-aside {
  background: white;
  bottom: 0;
  padding-top: 0.75rem;
  position: absolute;
  width: calc(var(--aside-width) - 40px);
}

.cart-line-quantiy {
  display: flex;
}

.cart-discount {
  align-items: center;
  display: flex;
  margin-top: 0.25rem;
}

.cart-subtotal {
  align-items: center;
  display: flex;
}
/*
* --------------------------------------------------
* components/Search
* --------------------------------------------------
*/
.predictive-search {
  height: calc(100vh - var(--header-height) - 40px);
  overflow-y: auto;
}

.predictive-search-form {
  background: var(--color-light);
  position: sticky;
  top: 0;
}

.predictive-search-result {
  margin-bottom: 2rem;
}

.predictive-search-result h5 {
  text-transform: uppercase;
}

.predictive-search-result-item {
  margin-bottom: 0.5rem;
}

.predictive-search-result-item a {
  align-items: center;
  display: flex;
}

.predictive-search-result-item a img {
  margin-right: 0.75rem;
  height: 100%;
}

.search-result {
  margin-bottom: 1.5rem;
}

.search-results-item {
  margin-bottom: 0.5rem;
}

/*
* --------------------------------------------------
* routes/__index
* --------------------------------------------------
*/
.featured-collection {
  display: block;
  margin-bottom: 2rem;
  position: relative;
}

.featured-collection-image {
  aspect-ratio: 1 / 1;
  @media (min-width: 45em) {
    aspect-ratio: 16 / 9;
  }
}

.featured-collection img {
  height: auto;
  max-height: 100%;
  object-fit: cover;
}

.recommended-products-grid {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(2, 1fr);
  @media (min-width: 45em) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.recommended-product img {
  height: auto;
}

/*
* --------------------------------------------------
* routes/collections.index.tsx
* --------------------------------------------------
*/
.collections-grid {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(var(--grid-item-width), 1fr));
  margin-bottom: 2rem;
}

.collection-item img {
  height: auto;
}

/*
* --------------------------------------------------
* routes/collections.$handle.tsx
* --------------------------------------------------
*/
.collection-description {
  margin-bottom: 1rem;
  max-width: 95%;
  @media (min-width: 45em) {
    max-width: 600px;
  }
}

.products-grid {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(var(--grid-item-width), 1fr));
  margin-bottom: 2rem;
}

.product-item img {
  height: auto;
  width: 100%;
}

/*
* --------------------------------------------------
* routes/products.$handle.tsx
* --------------------------------------------------
*/
.product {
  display: grid;
  @media (min-width: 45em) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 4rem ;
  }
}

.product h1 {
  margin-top: 0;
}

.product-images {
  display: grid;
  grid-gap: 1rem;
}

.product-image img {
  height: auto;
  width: 100%;
}

.product-main {
  align-self: start;
  position: sticky;
  top: 6rem;
}

.product-price-on-sale {
  display: flex;
  grid-gap: 0.5rem;
}

.product-price-on-sale s {
  opacity: 0.5;
}

.product-options-grid {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 0.75rem;
}

.product-options-item {
  padding: 0.25rem 0.5rem;
}

/*
* --------------------------------------------------
* routes/blog.index.tsx
* --------------------------------------------------
*/
.container {
  background-color: white;
  padding-top: 6rem;
  padding-bottom: 6rem;
}

@media (min-width: 640px) {
  .container {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
}

.content-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 112rem; /* max-w-7xl */
  padding-left: 1.5rem; /* px-6 */
  padding-right: 1.5rem;
}

@media (min-width: 1024px) { /* lg breakpoint */
  .content-wrapper {
    padding-left: 2rem; /* lg:px-8 */
    padding-right: 2rem;
  }
}

.grid-layout {
  margin-left: auto;
  margin-right: auto;
  max-width: 42rem; /* max-w-2xl */
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  align-items: start;
  gap: 2rem; /* gap-x-8, gap-y-16 */
}

@media (min-width: 640px) {
  .grid-layout {
    gap: 3rem; /* sm:gap-y-24 */
  }
}

@media (min-width: 1024px) { /* lg breakpoint */
  .grid-layout {
    margin-left: 0;
    max-width: none;
    grid-template-columns: repeat(2, 1fr);
  }
}

.left-panel {
  padding-right: 1rem; /* lg:pr-4 */
}

.card {
  position: relative;
  overflow: hidden;
  border-radius: 1.5rem; /* rounded-3xl */
  background-color: #1f2937; /* bg-gray-900 */
  padding-left: 1.5rem; /* px-6 */
  padding-bottom: 2.25rem; /* pb-9 */
  padding-top: 16rem; /* pt-64 */
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25); /* shadow-2xl */
}

@media (min-width: 640px) {
  .card {
    padding-left: 3rem; /* sm:px-12 */
  }
}

@media (min-width: 1024px) {
  .card {
    max-width: 37rem; /* lg:max-w-lg */
    padding-left: 2rem; /* lg:px-8 */
    padding-bottom: 2rem; /* lg:pb-8 */
  }

  .card .image-cover {
    filter: brightness(125%) saturate(0);
  }
}




/*
* --------------------------------------------------
* routes/blog.$articlehandle.tsx
* --------------------------------------------------
*/
.article img {
  height: auto;
  width: 100%;
}

/*
* --------------------------------------------------
* routes/account
* --------------------------------------------------
*/
.account-profile-marketing {
  display: flex;
  align-items: center;
}

.account-logout {
  display: inline-block;
}




